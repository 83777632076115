import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import genericSaga from "./Generic";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    genericSaga(),
  ]);
}
