import { GET_GENERIC, ADD_GENERIC, UPDATE_GENERIC } from "../../constants/ActionTypes";

export const getGeneric = (type) => {
  return {
    type: GET_GENERIC,
    payload: { type }
  };
};

export const addGeneric = (payload) => {
  return {
    type: ADD_GENERIC,
    payload: payload
  };
};

export const updateGeneric = (payload) => {
  return {
    type: UPDATE_GENERIC,
    payload: payload
  }
}