import { all, call, put, takeEvery } from "redux-saga/effects";
import { SIGNIN_USER } from "constants/ActionTypes";
import { showAuthMessage, userSignInSuccess } from "../../appRedux/actions/Auth";
import axios from "axios";
import { push } from 'react-router-redux';
let config = {
  API_URL: process.env.REACT_APP_DOMAIN_URL
  ? process.env.REACT_APP_DOMAIN_URL
  : `https://live.airzone.cleversell.io/`
}
const signInUserWithEmailPasswordRequest = async ({ branch, staffId, pin, type }) => {
  return await axios({
    method: 'get',
    url: config.API_URL + `${type}/_design/login/_view/new-view?key=[${branch},${staffId}, ${pin}]`,
  })
}

function* signInUserWithEmailPassword({ payload }) {
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload);
    localStorage.setItem('token', signInUser.data.rows[0].value);
    yield put(userSignInSuccess(signInUser.data.rows[0].value));
    yield put(showAuthMessage("welcome"));
    yield put(push('/'));

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export default function* rootSaga() {
  yield all([yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword)]);
}
