import {
  INIT_URL,
  USER_TOKEN_SET,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  UPDATE_USER,
  UPDATE_USER_SUCCESS
} from "../../constants/ActionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }
    case SIGNOUT_USER: {
      return { ...state, token: null, user: null, initURL: "" };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token
      };
    }
    case UPDATE_USER_SUCCESS: {
      return { ...state, user: action.payload.user };
    }
    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload.token
      };
    }
    case UPDATE_USER: {
      return {
        ...state,
        user: action.payload
      };
    }
    default:
      return state;
  }
};
