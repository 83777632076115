import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_GENERIC, GET_GENERIC_SUCCESS, ADD_GENERIC, UPDATE_GENERIC } from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import axios from 'axios'
import config from "./config";
import { push } from 'react-router-redux';
import { message } from 'antd';
import get from 'lodash/get';
import { toSingular } from "../../services/commonServices";

const genericAddApi = async (payload) => {
    return await axios({
        method: 'POST',
        url: config.url + payload.type,
        data: payload.value,
        params: { token: localStorage.getItem("token") }
    })
}

const genericGetApi = async (type) => {
    return await axios.get(config.url + type, { params: { token: localStorage.getItem("token") } })
        .then((response) => {
            return response.data;
        })
}

const genericUpdateApi = async ({ type, value, _id }) => {
    return await axios({
        method: 'PUT',
        url: config.url + type,
        data: value,
        params: { _id, token: localStorage.getItem("token") }
    });
}

function* genericAdd(action) {
    try {
        message.loading('', 0);
        yield call(genericAddApi, action.payload);
        message.destroy();
        message.success(toSingular(action.payload.type) + " added successfully!");
        yield put(push(`/list/${action.payload.type}`));
    } catch (error) {
        message.destroy();
        if (error.message == 'Network Error') message.error('Something Went Wrong!');
        yield put(fetchError(error));
    }
}


function* genericUpdate(action) {
    try {
        message.loading('', 0);
        yield call(genericUpdateApi, action.payload);
        message.destroy();
        if (get(action, 'payload.value.isDeleted')) message.success(toSingular(action.payload.type) + " deleted successfully!");
        else message.success(toSingular(action.payload.type) + " updated successfully!");
        yield put(push(`/list/${action.payload.type}`));
    } catch (error) {
        message.destroy();
        if (error.message == 'Network Error') message.error('Something Went Wrong!');
        yield put(fetchError(error));
    }
}

function* genericGet(action) {
    let type = action.payload.type;
    try {
        const fetchedData = yield call(genericGetApi, type);
        yield put({ type: GET_GENERIC_SUCCESS, payload: { [type]: fetchedData, type: type } });
    } catch (error) {
        yield put(fetchError(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeEvery(GET_GENERIC, genericGet),
        yield takeEvery(ADD_GENERIC, genericAdd),
        yield takeEvery(UPDATE_GENERIC, genericUpdate),

    ]);
}