import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';
import IntlMessages from '../../util/IntlMessages';
import { connect } from 'react-redux';


const SubMenu = Menu.SubMenu;
const SideBarCrud = ({ viewName, textName, iconName, key, routes, actions, canAccess, canAdd, ...props }) => {
   let loggedInUser = props.user.type
   return (
     viewName === "reports" ?
     canAccess?canAccess.includes(loggedInUser)?
        <SubMenu
          key="sub1"
          title={
            <span>
              <Icon style={{ right: 28 }} className="icon-style" type={iconName} />
              <span>
                <IntlMessages id={`sidebar.${viewName}`} />
              </span>
            </span>
          }
          {...props}
          className="sidebar-sub-menu"
        >
          {loggedInUser !== "9" && (
          <Menu.Item key={key + 1} style={{ marginRight: '10px' }}>
            <Link to={`/list/VrReports`}>
              <div>{'View'}<IntlMessages id={` VR Reports`} /></div>
            </Link>
          </Menu.Item>
          )}
          <Menu.Item key={key + 1} style={{ marginRight: '10px' }}>
            <Link to={`/list/${viewName}`}>
              <div>{'View '}<IntlMessages id={`sidebar.${viewName}`} /></div>
            </Link>
          </Menu.Item>
          {loggedInUser !== "9" && (
            <>
          <Menu.Item key={key + 1} style={{ marginRight: '10px' }}>
            <Link to={`/list/Orderreports`}>
              <div>{'View'}<IntlMessages id={` Order Reports`} /></div>
            </Link>
          </Menu.Item>
          <Menu.Item key={key + 1} style={{ marginRight: '10px' }}>
            <Link to={`/list/Stockreports`}>
              <div>{'View'}<IntlMessages id={` Stock Reports`} /></div>
            </Link>
          </Menu.Item>
          </>)}
        </SubMenu>  :null:''
      :
      <SubMenu
        key="sub1"
        title={
          <span>
            <Icon style={{ right: 28 }} className="icon-style" type={iconName} />
            <span>
              <IntlMessages id={`sidebar.${viewName}`} />
            </span>
          </span>
        }
        {...props}
        className="sidebar-sub-menu"
      >
        {canAdd?canAdd.includes(loggedInUser) ?
          <Menu.Item key={key} style={{ marginRight: '10px' }} >
            <Link to={`/add/${textName}`}>
              <div>{'Add '}<IntlMessages id={`sidebar.${viewName}`} /></div>
            </Link>
          </Menu.Item> : null:''}
        {canAccess?canAccess.includes(loggedInUser) ?
          <Menu.Item key={key + 1} style={{ marginRight: '10px' }}>
            <Link to={`/list/${viewName}`}>
              <div>{'View '}<IntlMessages id={`sidebar.${viewName}`} /></div>
            </Link>
          </Menu.Item>
          : null:''}
      </SubMenu>
  )
};

const mapStateToProps = ({ auth }) => {
  return { routes: auth.routes, actions: auth.actions };
};
export default connect(mapStateToProps)(SideBarCrud);
SideBarCrud.propTypes = {
  viewName: PropTypes.node.isRequired,
  textName: PropTypes.node.isRequired,
  iconName: PropTypes.node.isRequired,
  canAccess: PropTypes.array.isRequired,
  canAdd: PropTypes.array.isRequired
};

SideBarCrud.defaultProps = {};
