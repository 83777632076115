import config from "../appRedux/sagas/config";
import axios from 'axios';
import get from "lodash/get";

export function handleFormSubmit({ form, action, type, _id }) {
  form.validateFields((err, fieldsValue) => {
    if (err) {
      return;
    }
    if (type === 'companyProducts') {
      fieldsValue.packages = toArray(fieldsValue.packages);
      fieldsValue.prices = toArray(fieldsValue.prices);
    }
    if (type === 'companies') {
      fieldsValue.distributors = toArray(fieldsValue.distributors);
      fieldsValue.reps = toArray(fieldsValue.reps);
    }
    if (type === 'distributors') {
      fieldsValue.reps = toArray(fieldsValue.reps);
      fieldsValue.companies = toArray(fieldsValue.companies);
    }
    let payload = {
      type: type,
      value: fieldsValue,
      "_id": _id
    }
    action(payload);
    form.resetFields();
  });
}

function toArray(obj) {
  var array = [];
  for (let index = 0; index < Object.keys(obj).length; index++) {
    const key = Object.keys(obj)[index];
    if (key == index && (get(Object.keys(obj[key]), 'length', 0) > 0)) array.push(obj[key]);
  }
  return array;
}

export function addRow({ setFieldsValue, getFieldValue, staffs }) {
  let array = getFieldValue(staffs);
  setFieldsValue({ [staffs + 'Count']: getFieldValue(staffs + 'Count') + 1 });
  array[getFieldValue(staffs + 'Count')] = {};
  setFieldsValue({ [staffs]: array });
}

export function removeRow({ setFieldsValue, getFieldValue, staffs }) {
  let array = getFieldValue(staffs);
  setFieldsValue({ [staffs + 'Count']: getFieldValue(staffs + 'Count') - 1 });
  array[getFieldValue(staffs + 'Count')] = {};
  setFieldsValue({ [staffs]: array });
}

export function clearUnselectedZones({ setFieldsValue, getFieldValue, staffs }) {
  for (let i = 0; i < new Array(getFieldValue(staffs+'Count')).fill(0).length; i++) {
    if (!getFieldValue(staffs+'[' + i + '].zones')) return;
    for (let j = 0; j < getFieldValue(staffs+'[' + i + '].zones').length; j++) {
      let found = false;
      const staffZone = getFieldValue(staffs+'[' + i + '].zones')[j];
      for (let index = 0; index < getFieldValue('zones').length; index++) {
        const zone = getFieldValue('zones')[index];
        if (staffZone == zone) found = true;
      }
      if (!found) {
        let zones = getFieldValue(staffs+'[' + i + '].zones');
        zones.splice(j, 1);
        setFieldsValue({ [staffs+"[" + i + "].zones"]: zones });
      }
    }
  }
}

export function clearUnselectedStaffs({ setFieldsValue, getFieldValue, staffs, staff, reduxStaffs }){
  for (let i = 0; i < new Array(getFieldValue(staffs+'Count')).fill(0).length; i++) {
      let staffObj = getObjectWithId(getFieldValue(staffs+'[' + i + '].'+staff), reduxStaffs );
      for (let j = 0; j < get(staffObj, 'zones', []).length; j++) {
          let found = false;
          const staffZone = staffObj.zones[j];
          if(getFieldValue(staffs+'[' + i + '].zones').length> 0) return;
          for (let index = 0; index < get(getFieldValue('zones'), 'length', 0); index++) {
              const zone = getFieldValue('zones')[index];
              if (get(staffZone, '_id', staffZone) == zone) found = true;
          }
          if (!found) {
              setFieldsValue({ [staffs+"[" + i + "]."+staff]: "" });
          }
      }
  }
}

export function onZonesChange({ setFieldsValue, getFieldValue, staffs, staff, reduxStaffs }){
  let staffsArray = getFieldValue(staffs);
  let zones = getFieldValue('zones');
  if (!staffsArray) staffsArray = {};
  if (zones.length > 0) { staffsArray[0] = {} }
  else staffsArray = {};
  setFieldsValue({ [staffs]: staffsArray });
  if (Object.keys(staffsArray).length === 0) setFieldsValue({ [staffs+'Count']: 0 })
  else {
      if (getFieldValue(staffs+'Count') === 0) setFieldsValue({ [staffs+'Count']: 1 });
      else setFieldsValue({ [staffs+'Count']: getFieldValue(staffs+'Count') })
  }
  clearUnselectedZones({ setFieldsValue, getFieldValue, staffs });
  clearUnselectedStaffs({ setFieldsValue, getFieldValue, staffs, staff, reduxStaffs });
}

export function getObjectWithId(id, array) {
  for (let i = 0; i < get(array,'length', 0); i++) {
    if (id == array[i]._id) {
      return array[i]
    }
  }
}

export function plural(string) {
  let result = string.toLowerCase();
  if (string.charAt(string.length - 1) === 'y') {
    result = result.substring(0, result.length - 1);
    result += 'ie'
  }
  result += 's';
  return result;
}

export function toSingular(string) {
  let result = string.toLowerCase();
  result = capitalizeFirstLetter(result);
  if ( string.charAt(string.length - 3) === 'i' && string.charAt(string.length - 2) === 'e') {
    result = result.substring(0, result.length - 3);
    result += 'y';
  }
  else result = result.substring(0, result.length - 1);
  return result;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getObjectsOfIds(arrayofIds, arrayOfObjects) {
  let result = [];
  for (let i = 0; i < arrayofIds.length; i++) {
    const id = arrayofIds[i];
    for (let index = 0; index < arrayOfObjects.length; index++) {
      const object = arrayOfObjects[index];
      if (get(object, '_id') === id) {
        result.push(object)
      }
    }
  }
  return result;
}

export function deleteImageFromS3(image) {
  return axios.post(config.url + 'images/delete', {
    imageUrl: image
  });
}

export function toPrice(price) {
  if (!price && price !== 0) return null;
  if((typeof price) === 'string') price = price*1;
  return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}