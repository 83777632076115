import React from "react";
import { Button, Form, Input, message, Select } from "antd";
import { connect } from "react-redux";
import { userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { login, getBranchesUrl } from '../urls'
const FormItem = Form.Item;
const Option = Select.Option;


class SignIn extends React.Component {
  state = {
    branches: [],
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) { return; }
      fieldsValue.branch = JSON.stringify(fieldsValue.branch)
      fieldsValue.staffId = JSON.stringify(fieldsValue.staffId)
      fieldsValue.pin = JSON.stringify(fieldsValue.pin)
      fieldsValue.type = 'staff'
      this.props.userSignIn(fieldsValue)
      // login(JSON.stringify(fieldsValue.branch), JSON.stringify(fieldsValue.staffId), JSON.stringify(fieldsValue.pin)).then((response) => {
      //   localStorage.setItem('token', JSON.stringify(response.data.rows[0].value));

      //   message.config({
      //     top: 20,
      //     // duration: 5000,
      //     maxCount: 100,
      //   });
      //   const hide = message.success('Welcome', .01);
      //   // setTimeout(hide, 1.5);
      //   // message.success('Welcome')
      //   this.props.history.push("/")
      // }).catch((err) => {
      //     message.config({
      //       top: 20,
      //       duration: 0.5,
      //     });
      //     const hide =
      //       message.error('invalid user');
      // }


      // setTimeout(hide, 200);
      // )
      // message.destroy()
    })
  };
  componentDidMount() {
    getBranchesUrl().then((response) => {

      this.setState({ branches: response.data.rows.map((row) => ({ label: row.value.name, value: row.value._id })) })
    })

  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signIn" /></h1>
                <p><IntlMessages id="app.userAuth.bySigning" /></p>
                <p><IntlMessages id="app.userAuth.getAccount" /></p>
              </div>
              <div className="gx-app-logo">
                <img alt="" src={require("assets/images/cleversell.png")} />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">


                <FormItem>


                  {getFieldDecorator("branch", {
                    rules: [{
                      required: true, message: 'Please select your Branch! ',
                    }],
                  })(
                    <Select
                      showArrow={true}
                      mode="default"
                      placeholder="Select Branch"
                      onChange={this.onChange}
                    >
                      {this.state.branches.map(data => (

                        <Option style={{ textAlign: 'left', width: '100%' }} value={data.value} >

                          {data.label}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('staffId', {
                    rules: [{ required: true, message: 'Please enter your Id ! ' }],
                  })(
                    <Input placeholder="Staff Id" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('pin', {
                    rules: [{ required: true, message: 'Please enter your Pin!' }],
                  })(
                    <Input type="password" placeholder="Pin" />
                  )}
                </FormItem>

                <FormItem className="sign-form">
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                  {/* <span><IntlMessages id="app.userAuth.or" /></span> <Link className="sign-button" to="/signup"><IntlMessages
                    id="app.userAuth.signUp" /></Link> */}
                </FormItem>
              </Form>
            </div>
            <InfoView />
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token }
};

export default connect(mapStateToProps, { userSignIn })(WrappedNormalLoginForm);
