import { SHOW_MESSAGE, SIGNIN_USER_SUCCESS, SIGNIN_USER } from "constants/ActionTypes";
import { SIGNOUT_USER } from "../../constants/ActionTypes";
import get from 'lodash/get';

export const userSignIn = ({ branch, staffId, pin, type }) => {
  return {
    type: SIGNIN_USER,
    payload: {
      branch, staffId, pin, type
    }
  }
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };

};






export const userSignInSuccess = authUser => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: {
      user: authUser,
      token: get(authUser, "token")
    }
  };
};
// export const userSignInSuccess = (authUser) => {
//   let role;
//   if (get(authUser, ""))
//     return {
//       type: SIGNIN_USER_SUCCESS,
//       payload: { user: authUser, actions: get(authUser, 'job', 'Superadmin'), token: get(authUser, 'token') }
//     }
// };

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};