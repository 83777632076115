import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { Link } from 'react-router-dom'

class UserInfo extends Component {

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        {/* <li>My Account</li>
        <li>Connections</li> */}
        {/* <Link to={"/signin"}><li onClick={() =>localStorage.clear()}> Logout */}
     
 
         <Link to={"/signin"}><li onClick={() => this.props.userSignOut()}> Logout  
        </li></Link>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
        trigger="click">
        <Avatar img alt="" src={require('assets/images/crown.jpg')}
          className="gx-avatar gx-pointer" />
      </Popover>
    )

  }
}

export default connect(null, { userSignOut })(UserInfo);
