import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        exact
        path={`${match.url}`}
        component={asyncComponent(() =>
          // import("../routes/main/dashboard/Crypto/index")
          import("./components/MainDashboard")
        )}
      />
      <Route
        exact
        path={`${match.url}dashboard`}
        component={asyncComponent(() =>
          import("./components/MainDashboard")
        )}
      />
      <Route
        path={`${match.url}add/superadmins`}
        component={asyncComponent(() =>
          import("../components/SuperAdmin/AddAdmin")
        )}
      />
      <Route
        path={`${match.url}list/superadmins`}
        component={asyncComponent(() =>
          import("../components/SuperAdmin/ViewAdmin")
        )}
      />

      <Route
        path={`${match.url}update/superadmins`}
        component={asyncComponent(() =>
          import("../components/SuperAdmin/AddAdmin")
        )}
      />

      <Route
        path={`${match.url}list/rooms`}
        component={asyncComponent(() =>
          import("../components/Rooms/ViewRooms")
        )}
      />
      <Route
        path={`${match.url}add/rooms`}
        component={asyncComponent(() => import("../components/Rooms/AddRoom"))}
      />
      <Route
        path={`${match.url}update/rooms`}
        component={asyncComponent(() => import("../components/Rooms/AddRoom"))}
      />
      <Route
        path={`${match.url}list/branches`}
        component={asyncComponent(() =>
          import("../components/Branches/ViewBranches")
        )}
      />
      <Route
        path={`${match.url}add/branches`}
        component={asyncComponent(() =>
          import("../components/Branches/AddBranch")
        )}
      />
      <Route
        path={`${match.url}update/branches`}
        component={asyncComponent(() =>
          import("../components/Branches/AddBranch")
        )}
      />
      <Route
        path={`${match.url}list/customers`}
        component={asyncComponent(() =>
          import("../components/Customer/ViewCustomers")
        )}
      />
      <Route
        path={`${match.url}add/customers`}
        component={asyncComponent(() =>
          import("../components/Customer/AddCustomers")
        )}
      />
      <Route
        path={`${match.url}update/customers`}
        component={asyncComponent(() =>
          import("../components/Customer/AddCustomers")
        )}
      />
      <Route
        path={`${match.url}list/Products`}
        component={asyncComponent(() =>
          import("../components/Products/ViewProducts")
        )}
      />
      <Route
        path={`${match.url}add/products`}
        component={asyncComponent(() =>
          import("../components/Products/AddProduct")
        )}
      />
      <Route
        path={`${match.url}update/products`}
        component={asyncComponent(() =>
          import("../components/Products/AddProduct")
        )}
      />
      <Route
        path={`${match.url}list/Promotions`}
        component={asyncComponent(() =>
          import("../components/Promo/ViewPromotions")
        )}
      />
      <Route
        path={`${match.url}add/Promotions`}
        component={asyncComponent(() => import("../components/Promo/AddPromo"))}
      />
      <Route
        path={`${match.url}update/Promotions`}
        component={asyncComponent(() => import("../components/Promo/AddPromo"))}
      />

      <Route
        path={`${match.url}list/add-on-products`}
        component={asyncComponent(() => import("../components/Adds/View-Adds"))}
      />
      <Route
        path={`${match.url}add/add-on-products`}
        component={asyncComponent(() => import("../components/Adds/Add-Adds"))}
      />
      <Route
        path={`${match.url}update/add-on-products`}
        component={asyncComponent(() => import("../components/Adds/Add-Adds"))}
      />
      <Route
        path={`${match.url}list/staff`}
        component={asyncComponent(() =>
          import("../components/Staff/ViewStaff")
        )}
      />
      <Route
        path={`${match.url}add/staff`}
        component={asyncComponent(() =>
          import("../components/Staff/AddStaff")
        )}
      />
      <Route
        path={`${match.url}update/staff`}
        component={asyncComponent(() =>
          import("../components/Staff/AddStaff")
        )}
      />
      <Route
        path={`${match.url}list/permissions`}
        component={asyncComponent(() =>
          import("../components/Permissions/ViewPermissions")
        )}
      />
      <Route
        path={`${match.url}add/permissions`}
        component={asyncComponent(() =>
          import("../components/Permissions/AddPermissions")
        )}
      />
      <Route
        path={`${match.url}update/permissions`}
        component={asyncComponent(() =>
          import("../components/Permissions/EditPermissions")
        )}
      />
      <Route
        path={`${match.url}list/category`}
        component={asyncComponent(() =>
          import("../components/Category/ViewCategory")
        )}
      />
      <Route
        path={`${match.url}add/category`}
        component={asyncComponent(() =>
          import("../components/Category/AddCategory")
        )}
      />
      <Route
        path={`${match.url}update/category`}
        component={asyncComponent(() =>
          import("../components/Category/AddCategory")
        )}
      />
      <Route
        path={`${match.url}list/sub-category`}
        component={asyncComponent(() =>
          import("../components/SubCategory/ViewSubCategory")
        )}
      />
      <Route
        path={`${match.url}add/sub-category`}
        component={asyncComponent(() =>
          import("../components/SubCategory/AddSubCategory")
        )}
      />
      <Route
        path={`${match.url}update/sub-category`}
        component={asyncComponent(() =>
          import("../components/SubCategory/AddSubCategory")
        )}
      />



      <Route
        path={`${match.url}add/session`}
        component={asyncComponent(() =>
          import("../components/Sessions/AddSession")
        )}
      />
      <Route
        path={`${match.url}list/sessions`}
        component={asyncComponent(() =>
          import("../components/Sessions/ViewSessions")
        )}
      />
      <Route
        path={`${match.url}update/sessions`}
        component={asyncComponent(() =>
          import("../components/Sessions/EditSession")
        )}
      />

      <Route
        path={`${match.url}add/memberships`}
        component={asyncComponent(() =>
          import("../components/Membership/AddMemberships")
        )}
      />
      <Route
        path={`${match.url}list/memberships`}
        component={asyncComponent(() =>
          import("../components/Membership/ViewMemberships")
        )}
      />
      <Route
        path={`${match.url}update/memberships`}
        component={asyncComponent(() =>
          import("../components/Membership/AddMemberships")
        )}
      />

      <Route
        path={`${match.url}list/reports`}
        component={asyncComponent(() =>
          import("../components/Reports/ViewReports")
        )}
      />
      <Route
        path={`${match.url}list/Vrreports`}
        component={asyncComponent(() =>
          import("../components/Reports/ViewVrReports")
        )}
      />
       <Route
        path={`${match.url}list/Stockreports`}
        component={asyncComponent(() =>
          import("../components/Reports/ViewStockReports")
        )}
      />
      <Route
        path={`${match.url}list/Orderreports`}
        component={asyncComponent(() =>
          import("../components/Reports/ViewOrderReports")
        )}
      />
    </Switch>
  </div>
);

export default App;
